@import "../../DesignSystem/library";

.clem-transitiuon-page {
  font-family: $font-primary;
  margin-top: 4rem;
  padding: 0rem 4rem;
  @media (max-width: 768px) {
    padding: 1.5rem;
  }
  @media (max-width: 480px) {
    padding: 0.8rem 1rem 1.2rem 1rem;
    margin-top: 0rem;
  }

  .hero-section-container {
    width: 100%;
    height: 556px;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    border-radius: 39px;
    color: #ffffff;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 98px;
    font-weight: 200;

    @media (max-width: 768px) {
      font-size: 76px;
      height: 500px;
    }

    @media (max-width: 970px) {
      font-size: 50px;
    }
    @media (max-width: 480px) {
      font-size: 28px;
      height: 372px;
      border-radius:16px;
    }
  }

  .pc-video-container {
    margin-top: 3rem;
    border-radius: 25px;
    height: 542px;
    width: 100%;
    border: none;

    video {
      border-radius: 25px;
      object-fit: cover;
    }
  }

  .clem-stretegy-container {
    padding: 3rem;
    border: none;
    margin-top: 2rem;
    margin-bottom: 2rem;
    background-color: #ffffff;
    width: 100%;
    border-radius: 32px;

    @media (max-width: 768px) {
    }
    @media (max-width: 480px) {
      padding: 1rem;
      border-radius: 16px;
      margin-top: 1rem;
      margin-bottom: 1rem;
    }

    .clem-stretegy-heading {
      font-size: 48px;
      font-weight: 450;
      color: #111111;

      span {
        font-weight: 550;
        color: #1058c0;
      }

      @media (max-width: 768px) {
      }
      @media (max-width: 480px) {
        font-size: 24px;
      }
    }
    .divider {
      background-color: #111111;
      width: 100%;
      height: 1px;
      margin-top: 30px;

      @media (max-width: 480px) {
        margin-top: 15px;
      }
    }
    .clem-stretegy-description-container {
      display: flex;
      justify-content: flex-end;
      margin-top: 3rem;
      margin-bottom: 4.5rem;

      .clem-stretegy-description {
        width: 40%;
        font-size: 20px;
        font-weight: 300;
        line-height: 24px;
        padding: 5px;

        @media (max-width: 480px) {
          width: 100%;
          margin-bottom: -50px;
          margin-top: -30px;
          font-size: 15px;
          font-weight: 400;
        }
      }
    }
  }



  .get-in-touch-container {
    display: flex;
    height: 515px;
    gap: 1.5rem;
    font-family: $font-primary;
    letter-spacing: 1px;
    margin-bottom: 3rem;

    .git-image-container {
      border-radius: 20px;
      flex: 1.3;

      img {
        border-radius: 20px;
      }
    }

    .git-card {
      border-radius: 20px;
      background-color: white;
      flex: 2;
      padding: 0rem 3rem;
      padding-top: 3rem;
      position: relative;

      .git-card-content {
        font-size: 52px;
        font-weight: 300;
        margin-bottom: 4rem;
        width: 500px;

        span {
          font-weight: 600;
          color: $blue-100;
        }
      }
    }

    .separator {
      width: 100%;
      height: 1px;
      background-color: black;
      margin-top: 2rem;
    }

    .git-btn-container {
      position: absolute;
      bottom: 2.5rem;
      right: 3rem;
    }

    @media screen and (max-width: 950px) {
      height: calc(249.86px - 2rem);
      margin-top: 0rem;
      margin-bottom: 3rem;
      letter-spacing: 0.5px;

      .git-card {
        height: 249.86px;
        padding: 1.5rem;
        .git-card-content { 
          width: 100%;
          font-size: 22.32px;
        }

        .separator {
          width: 100%;
          height: 0.5px;
          background-color: black;
          margin-top: 1rem;
        }

        .git-btn-container {
          position: absolute;
          bottom: 1rem;
          right: 1rem;
        }
      }
    }
  }

  @media (max-width: 950px) {

    .pc-video-container {
      margin-top: 1rem;
      margin-left: 0rem;
      border-radius: 10px;
      height: 30vh;
      width: calc(100%);
      border-radius: 10px;

      video {
        border-radius: 10px;
      }
    }
  }

}
