@import "../../../../DesignSystem/library";

.element-container {
  display: flex;
  align-items: center;
  margin-bottom: 1.5rem;

  .element-container-icon {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: $blue-200;
    padding: 0.5rem 0.6rem;
    border-radius: 4px;
    margin-right: 0.8rem;
  }

  .element-container-text {
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    p:first-child {
      font-weight: 400;
      font-size: 14px;
      color: black;
      margin-bottom: 0.4rem;
    }
    p:last-child {
      font-size: 12px;
      font-weight: 300;
      color: $gray400;
    }
  }
}
