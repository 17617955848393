@import "../../../DesignSystem/library";

.footer-container {
  background-color: $black-200;
  display: flex;
  font-family: $font-primary;
  color: #ffffff;
  padding: 2rem 3rem;

  .clem-email-sm-container {
    display: flex;
    flex-direction: column;
    flex: 1.2;
    height: 100%;
    position: relative;

    .clem-text {
      font-family: $font-quinary;
      font-weight: 500;
      font-size: 40px;
      margin-bottom: 0.2rem;
    }
    .clem-desc {
      font-weight: 300;
      font-size: 16px;
      letter-spacing: 1px;
    }
    .email-input-container {
      display: flex;
      margin: 0.8rem 0rem;
      height: 38px;
      input {
        width: 80%;
        border-radius: 4px;
        margin-right: 0.2rem;
        border: none;
        outline: none;
        padding-left: 1rem;
        font-family: $font-primary;
        letter-spacing: 1px;
        @media (max-width: 600px) {
          padding-left: 10px;
          &::placeholder {
            font-size: 8px;
          }
        }
      }
    }
    .social-media-links-container {
      display: flex;
      align-items: center;
      gap: 1.5rem;
      margin-top: 1rem;
      div {
        cursor: pointer;
      }
    }
    .copyrights {
      margin-top: 60px;
      font-size: 16px;
      font-weight: 300;
    }
  }

  .right-container {
    flex: 2;
    padding-left: 8rem;

    .spcr-container {
      display: flex;
      gap: 1.6rem;

      .solutions-container,
      .platforms-container,
      .company-container,
      .resources-container {
        font-size: 18px;
        letter-spacing: 1px;
        margin-right: 2.4rem;

        .heading {
          font-weight: 700;
          margin-bottom: 2rem;
        }

        .child-container {
          display: flex;
          flex-direction: column;
          gap: 0.4rem;
          p {
            font-size: 16px;
            font-weight: 300;
            cursor: pointer;
          }
        }
      }
      .platforms-container {
        .child-container {
          p {
            color: #535353;
            cursor: not-allowed;
          }
        }
      }
    }

    .address-container {
      display: flex;
      gap: 1.6rem;
      margin: 3.5rem 0 2.5rem 0;

      .inner-container {
        flex: 1;
        .title {
          font-weight: 700;
          margin-bottom: 0.6rem;
        }

        .address-details {
          font-size: 16px;
          font-weight: 300;
          cursor: pointer;
          letter-spacing: 0.5px;
          line-height: 20px;

          span {
            text-wrap: nowrap;
          }
        }
      }
    }

    .tps-container {
      display: flex;
      gap: 1.6rem;
      font-family: $font-primary;
      font-size: 16px;
      font-weight: 300;
      cursor: pointer;
      letter-spacing: 1px;

      .tps-disabled {
        color: #535353;
        cursor: not-allowed;
      }
    }
  }

  @media screen and (max-width: 980px) {
    flex-direction: column;
    justify-content: start;
    height: 100%;
    padding: 2rem;

    .clem-email-sm-container {
      margin-top: 1rem;
      .clem-logo {
        height: 41.76px;
        width: 100px;
      }
      .clem-desc {
        margin-top: 2rem;
        font-size: 14px;
      }
    }

    .spcr-container-mobile {
      position: relative;
      padding-left: 0rem;
      flex-direction: column;
      margin-top: 1.2rem;

      .address-container-mobile {
        display: flex;
        flex-direction: column;
        gap: 1.4rem;
        margin-top: 2.5rem;

        .inner-container {
          .title {
            font-weight: 700;
            font-size: 18px;
            margin-bottom: 0.5rem;
          }

          .address-details {
            font-size: 16px;
            font-weight: 300;
            cursor: pointer;
            letter-spacing: 0.5px;
            line-height: 20px;

            span {
              text-wrap: nowrap;
            }
          }
        }
      }

      .cr-wrapper {
        display: flex;
        gap: 4rem;
        margin-top: 2.5rem;
        @media (max-width: 600px) {
          flex-direction: column;
          gap: 2rem;
        }
      }

      .solutions-container-mobile,
      .platforms-container-mobile,
      .company-container-mobile,
      .resources-container-mobile,
      .tps-container-mobile {
        .heading-mobile {
          font-weight: 700;
          margin-bottom: 1rem;
          font-size: 18px;
        }

        .child-container-mobile {
          display: flex;
          flex-direction: column;
          gap: 0.4rem;
          p {
            font-size: 16px;
            font-weight: 300;
            cursor: pointer;
            color: $gray700;
          }
        }

        .child-container-mobile-platform {
          p {
            color: #535353;
          }
        }
      }
    }

    .tps-container-mobile {
      margin-top: 1.5rem;

      .child-container-mobile {
        display: flex;
        gap: 1rem;
      }

      .tps-disabled {
        color: #535353 !important;
        cursor: not-allowed;
      }
    }
  }
}
