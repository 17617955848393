@import "../../../DesignSystem/library";

.get-in-touch-container {
  display: flex;
  margin: 2rem 0rem;
  margin-bottom: 4rem;
  padding: 0rem 4rem;
  height: 515px;
  gap: 1.5rem;
  font-family: $font-primary;
  letter-spacing: 1px;

  .git-image-container {
    border-radius: 20px;
    flex: 1.3;

    img {
      border-radius: 20px;
    }
  }

  .git-card {
    border-radius: 20px;
    background-color: white;
    flex: 2;
    padding: 0rem 3rem;
    padding-top: 3rem;
    position: relative;

    .git-card-content {
      font-size: 43px;
      font-weight: 450;
      width: 500px;

      span {
        font-weight: 500;
        color: $blue-100;
      }
    }
  }

  .separator {
    width: 100%;
    height: 1px;
    background-color: black;
    margin-top: 2rem;
  }

  .git-btn-container {
    position: absolute;
    bottom: 2.5rem;
    right: 3rem;
  }

  @media screen and (max-width: 950px) {
    padding: 0rem 1rem;
    height: calc(249.86px - 2rem);
    margin-top: 0rem;
    letter-spacing: 0.5px;

    .git-card {
      height: 249.86px;
      padding: 1.5rem;
      .git-card-content {
        width: 100%;
        font-size: 22.32px;
      }

      .separator {
        width: 100%;
        height: 0.5px;
        background-color: black;
        margin-top: 1rem;
      }

      .git-btn-container {
        position: absolute;
        bottom: 1rem;
        right: 1rem;
      }
    }
  }
}
