@import "../../DesignSystem/library";

.button-container {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: $blue-100;
  color: white;
  padding: 0.6rem 1rem;
  border-radius: 6px;
  font-weight: 400;
  font-size: 12px;
  cursor: pointer;
  width: fit-content;
  font-family: $font-primary;
  letter-spacing: 1px;
}
