@import "../../DesignSystem//library";

.description-card-container {
  gap: 1rem;
  width: 100%;
  font-family: $font-primary;
  display: grid;
  grid-template-columns: repeat(4, 1fr);

  @media (max-width: 1200px) {
    grid-template-columns: repeat(2, 1fr);
    .card {
      .heading-container {
        font-size: 20px;
      }
    }
  }

  @media (max-width: 600px) {
    gap: 1rem;
    grid-template-columns: repeat(1, 1fr);
    .card {
      .heading-container {
        font-size: 15px;
      }
    }
  }

  .card {
    width: 100%;
    height: 405px;
    border: none;
    border-radius: 21px;
    background-color: #eaf3ff;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    cursor: pointer;
    @media (max-width: 800px) {
      height: 350px;
    }
    @media (max-width: 600px) {
      height: 300px;
    }

    &:hover {
      background-color: #1868db;
    }

    .icon-container {
      padding: 25px;
      display: flex;
      justify-content: flex-end;
      // position: relative;
      z-index: 111;
    }

    .icon-container-hovered {
      svg {
        path {
          fill: white;
        }
      }
    }

    .heading-container {
      color: #1868db;
      font-size: 32px;
      font-weight: 600;
      line-height: 38px;
      padding-left: 30px;
      padding-bottom: 30px;
      display: flex;
      align-items: flex-end;
      flex-grow: 1;
      @media (max-width: 1200px) {
        font-size: 25px;
      }
      @media (max-width: 900px) {
        font-size: 22px;
      }
      @media (max-width: 600px) {
        font-size: 20px;
      }
    }

    &:hover .heading-container {
      display: none;
    }

    .description-container {
      display: none;
      transition: opacity 0.3s ease, visibility 0.3s ease;
      padding: 20px;
      font-size: 24px;
      color: #fff;
      font-weight: 400;
      @media (max-width: 800px) {
        font-size: 20px;
      }
      @media (max-width: 600px) {
        font-size: 17px;
      }
    }

    &:hover .description-container {
      display: flex;
      align-items: flex-end;
      flex-grow: 1;
    }
  }
}
