@import "../../DesignSystem/library";

.container {
  font-family: $font-primary;
  padding: 3rem;

  h1 {
    font-size: 2.2rem;
    margin-bottom: 2rem !important;
  }
}

.mainList {
  margin-left: 1rem;

  li {
    margin-bottom: 1.2rem;

    > * {
      margin-top: 0.8rem;
    }
  }

  p {
    margin-bottom: unset;
  }
}

.mainList > li::marker {
  font-weight: bold;
}

@media screen and (max-width: 768px) {
  .container {
    padding: 0.8rem 1.6rem;

    h1 {
      font-size: 1.8rem;
      margin-bottom: 1.6rem !important;
    }
  }

  .mainList {
    li {
      margin-bottom: 0.8rem;

      > * {
        margin-top: 0.6rem;
      }
    }
  }
}
