@import "../../../DesignSystem/library";

.header-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 2.5rem;
  padding-top: 1.6rem;
  font-size: 15px;
  font-family: $font-primary;

  .logo-container {
    cursor: pointer;
    svg {
      width: 140px;
      height: 55px;
      margin-top: 0.25rem;
    }

    @media screen and (max-width: 550px) {
      svg {
        width: 100px;
        height: 45px;
        margin-top: 0.25rem;
      }
    }
  }

  .header-options-container {
    display: flex;
    align-items: center;
    gap: 2rem;

    .dropdown-container {
      display: flex;
      align-items: center;
      gap: 2rem;

      .dropdown-header {
        position: relative;
        font-weight: 430;
        letter-spacing: 0.2px;

        .dropdown-header-text {
          cursor: pointer;
          span {
            margin-left: 0.6rem;
            svg {
              margin-bottom: 0.16rem;
            }
          }
        }

        .header-dropdown-options-container {
          position: absolute;
          top: 3rem;
          background-color: white;
          z-index: 2;

          .company-dropdown-container,
          .resources-dropdown-container {
            width: 100px;
            font-family: $font-tertiary;
            display: flex;
            flex-direction: column;
            gap: 1rem;
            font-weight: 400;
            p {
              cursor: pointer;
            }
          }
        }
      }
    }
  }

  .header-end-container {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: 2rem;
    width: 200px;

    .btn-container {
      display: flex;
      gap: 1rem;

      .seBtn {
        font-weight: 500;
        background-color: #288f90;
      }

      .btn {
        font-size: 12px;
        font-weight: 400;
      }
    }

    .language-dropdown-container {
      display: flex;
      align-items: center;
      cursor: pointer;
      font-weight: 450;
      letter-spacing: 0.2px;

      .language-svg-container {
        height: 15px;
        width: 15px;
        margin-right: 0.35rem;
        img {
          border-radius: 50%;
          object-fit: cover;
        }
      }

      .language-text {
        font-size: 15px;
      }

      .language-down-arrow-container {
        margin-left: 0.4rem;
        svg {
          margin-bottom: 0.1rem;
        }
      }
    }
  }

  @media screen and (max-width: 950px) {
    height: 61px;
    background-color: white;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 1rem;
    position: relative;
    border-bottom: 1px solid #dfe1a6;

    .bread-crumb-container {
      // margin-top: -1rem;
      cursor: pointer;
    }

    .seBtn {
      font-weight: 500;
      background-color: #288f90;
    }

    .header-popup-container {
      position: absolute;
      width: 100vw;
      height: calc(100vh - 55px);
      top: calc(100%);
      right: -100%;
      z-index: 1111;
      // border-top-right-radius: 10px;
      // border-bottom-right-radius: 10px;
      background-color: white;
      padding: 1rem 1.2rem;
      letter-spacing: 0.5px;
      box-shadow: 5px 4px 9.2px 0px #a4a4a440;
      transition: all 0.5s linear;

      .logo-exit-container {
        display: flex;
        justify-content: space-between;
        align-items: center;
        cursor: pointer;

        .se-logo-container {
          width: 60.83px;
          height: 41px;

          svg {
            width: 60.83px;
            height: 41px;
          }
        }
      }

      .hp-links-container {
        margin-top: 1.5rem;

        .separator {
          height: 0.5px;
          width: 98%;
          background-color: $gray600;
          z-index: 10;
          margin-top: 0.7rem;
        }

        .hp-link-element {
          cursor: pointer;
          margin-top: 1rem;
        }

        .git-btn-container {
          margin-top: 1rem;
        }
      }

      .clem-btn-container {
        margin: 1.5rem 0rem;
      }

      .get-in-touch-container {
        position: absolute;
        bottom: 1rem;
        right: 0.5rem; 
      }
    }

    .visible {
      animation: slideIn 0.5s;
      right: 0%;
    }

    .hide {
      right: 0%;
      animation: slideOut 0.5s;
    }

    @keyframes slideIn {
      0% {
        right: -50%;
      }
      // 50% {
      //   left: -50%;
      // }
      100% {
        right: 0%;
      }
    }

    @keyframes slideOut {
      0% {
        right: 0%;
      }
      // 50% {
      //   left: -50%;
      // }
      100% {
        right: -100%;
      }
    }
  }
}
