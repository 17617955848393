@import "../../../DesignSystem/library";

.homepage-container {
  position: relative;
  font-family: $font-primary;

  .home-page-image-text-container {
    position: relative;
    padding-top: 0;

    .home-page-image-container {
      z-index: 1;
      img {
        height: 100%;
        object-fit: contain;
      }
    }

    .white-image-layer {
      position: absolute;
      background-color: white;
      width: 80vw;
      height: 70vh;
      top: 1.5rem;
      left: 50%;
      transform: translate(-50%);
      border-top-right-radius: 40px;
      border-top-left-radius: 40px;
      z-index: -1;

      @media screen and (max-width: 550px) {
        width: 90vw;
      }
    }
    .home-page-content {
      position: absolute;
      left: 10vw;
      top: 8vh;
      padding: 2rem 4rem;
      width: 60%;
      z-index: 111;

      @media screen and (max-width: 550px) {
        left: 3vw !important;
        padding: 2rem;
      }

      .home-page-content-heading {
        font-size: 38px;

        .making-text {
          font-size: 38px;
          font-weight: 300;
        }

        .nt-text {
          font-size: 62px;
          font-weight: 400;
        }

        .profitable-text {
          font-size: 68px;
          font-weight: 500;
          color: $blue-100;
        }

        span {
          background: linear-gradient(
            90.24deg,
            $blue-100 17.47%,
            #6552dc 97.36%
          );
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
        }
      }

      .home-page-content-desc {
        width: 65%;
        font-family: $font-primary;
        color: $gray500;
        font-weight: 300;
        margin-top: 1rem;
        line-height: 24px;
        margin-bottom: 2rem;
        letter-spacing: 1px;
        font-size: 18px;

        @media screen and (max-width: 500px) {
          font-size: 12px !important;
          width: 95%;
          margin-bottom: 1rem;
        }
      }

      .watch-overview-btn {
        display: flex;
        align-items: center;
        gap: 0.5rem;
        font-size: 14px;
        

        @media screen and (max-width: 550px) {
          font-size: 10px;
          width: 110px;
          height: 16px;
          gap: 0.3rem;
          white-space: nowrap;
          
        }
       
      }
    }
  }

  .comment-btn {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    bottom: 3rem;
    right: 3rem;
    background-color: $blue-100;
    width: fit-content;
    border-radius: 50%;
    padding: 0.6rem;
  }

  @media screen and (max-width: 1000px) {
    .home-page-image-text-container {
      height: 100%;
      padding-top: 8rem;
      .home-page-image-container {
        height: 100%;
        z-index: 1;

        img {
          height: 100vh;
          object-fit: cover;
        }
      }
      .white-image-layer {
        height: 70vh;
        border-radius: 20px;
      }
      .home-page-content {
        left: 5vw;
        top: 8vh;
        width: 92%;

        .home-page-content-heading {
          width: 100%;
          .making-text {
            font-size: 22.45px;
          }
          .nt-text {
            font-size: 35.92px;
          }
          .profitable-text {
            font-size: 35.92px;
          }
        }

        .home-page-content-desc {
          line-height: 18px;
          font-size: 14px;
        }
      }
    }
  }

  // @media screen and (min-width: 600px) and (max-width: 1200px) {
  //   height: 100vh;

  //   .home-page-image-text-container {
  //     height: 100%;
  //     padding-top: 8rem;

  //     .home-page-image-container {
  //       height: 100%;
  //       z-index: 1;

  //       img {
  //         height: 100vh;
  //         object-fit: cover;
  //       }
  //     }
  //     .white-image-layer {
  //       height: 70vh;
  //     }
  //     .home-page-content {
  //       top: 8vh;
  //       left: 7%;
  //       width: 92%;

  //       .home-page-content-heading {
  //         width: 100%;
  //         .making-text {
  //           font-size: 22.45px;
  //         }
  //         .nt-text {
  //           font-size: 35.92px;
  //         }
  //         .profitable-text {
  //           font-size: 35.92px;
  //         }
  //       }

  //       .home-page-content-desc {
  //         font-size: 14px;
  //       }
  //     }
  //   }
  // }

  .black-screen {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.365);
    display: flex;
    justify-content: center;
    align-content: center;

    .video-container {
      height: 85vh;
      width: 83vw;
      position: absolute;
      top: 33%;
      left: 50%;
      transform: translate(-50%, -50%);
      z-index: 111;

      iframe {
        border: none;
      }
    }

    .close-icon-container {
      position: absolute;
      top: 0.1rem;
      right: -2.5rem;
      cursor: pointer;
    }
    @media (max-width: 600px) {
      .video-container {
        height: 30vh;
      }
      .close-icon-container {
        position: absolute;
        top: 1.7rem;
        right: -1.7rem;
        cursor: pointer;

        svg {
          width: 19px;
          height: 18px;
        }
      }
    }

    @media (min-width: 600px) and (max-width: 1000px) {
      .video-container {
        height: 40vh;
      }
    }
  }
}
