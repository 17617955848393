@import "../../../DesignSystem/library";

.prac-crafted-approach-container {
  margin-top: 4rem;
  padding: 0rem 4rem;
  font-family: $font-primary;

  .pc-text-image-container {
    display: flex;
    height: 50vh;
    gap: 4rem;
    border-left: 1px solid #000;

    .pc-vertical-line {
      height: 100%;
      width: 1px;
      background-color: black;
    }

    .pc-content {
      flex: 1;
      padding-left: 2.5rem;

      .pc-prac-text {
        font-weight: 300;
        font-size: 36px;
      }
      .pc-crafted-approach-text {
        font-size: 44px;
        color: $blue-100;
        font-weight: 500;
      }

      .pc-content-para-1,
      .pc-content-para-2 {
        line-height: 30px;
        font-size: 20px;
        font-weight: 400;
        width: 550px;
        letter-spacing: 0.5px;
      }
      .pc-content-para-1 {
        margin-top: 1rem;
      }
      .pc-content-para-2 {
        margin-top: 2rem;
      }
    }
    .pc-image {
      flex: 1.2;

      img {
        object-fit: cover;
        border-radius: 25px;
      }
    }
  }

  .pc-cards-container {
    display: flex;
    gap: 1rem;
    justify-content: space-between;
    margin-top: 3rem;

    > div {
      flex: 1;
    }

    .card-content {
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      height: 100%;
      padding: 0rem 0.5rem;
      padding-bottom: 0.5rem;
      position: relative;

      .card-icon {
        position: absolute;
        top: 0.1rem;
        right: 0.3rem;
      }

      .card-header {
        font-weight: 600;
        color: $blue-100;
        font-size: 32px;
      }

      .card-desc {
        font-size: 20px;
        font-weight: 400;
        letter-spacing: 0.5px;
      }
    }
  }

  .pc-video-container {
    margin-top: 3rem;
    border-radius: 25px;
    height: 65vh;
    width: 100%;
    border: none;

    video {
      border-radius: 25px;
      object-fit: cover;
    }
  }

  @media screen and (max-width: 950px) {
    margin-top: 1rem;
    padding: 0rem 1rem;

    .pc-text-image-container-mobile {
      background-color: $blue-800;
      border-radius: 20px;
      padding: 2rem;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      letter-spacing: 0.5px;

      .pc-prac-text {
        font-size: 14px;
      }

      .pc-crafted-approach-text {
        font-size: 16px;
        color: $blue-100;
        font-weight: 500;
      }

      .pc-content-para-1,
      .pc-content-para-2 {
        text-align: center;
        margin-top: 1rem;
        font-size: 18px;
      }
    }

    .pc-cards-container {
      overflow-x: scroll;
      margin-top: 1rem;

      > div {
        width: 168.05px;
        min-width: 168.05px;
      }

      .card-content {
        .card-header {
          font-size: 19px;
        }
        .card-desc {
          font-size: 11.94px;
        }
      }
    }

    .pc-video-container {
      margin-top: 1rem;
      margin-left: 0rem;
      border-radius: 0;
      height: 30vh;
      width: calc(100%);
      border-radius: 10px;

      video {
        border-radius: 10px;
      }
    }
  }
}
