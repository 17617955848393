@import "../../../DesignSystem/library";

.our-strategy-container-parent {
  margin-top: 2rem;
  font-family: $font-primary;

  .curved-white-container {
    padding: 3rem;
    background-color: white;
    border-radius: 40px;
    font-weight: 450;

    @media screen and (max-width: 550px) {
      padding: 2rem !important;
      border-radius: 16px;
    }

    .strategy-container {
      .os-title {
        width: fit-content;
        font-size: 36px;
        padding-right: 52px;
        margin-bottom: 0.5rem;

        span {
          color: $blue-100;
        }

        @media screen and (max-width: 550px) {
          font-size: 20px;
          white-space: nowrap;
          font-weight: 400;

          span {
            font-weight: 450;
          }
        }
      }
      .separator {
        width: 100%;
        height: 0.5px;
        background-color: black;
      }

      .strategy-img-container {
        width: 100%;
        margin: 4rem 0;
      }

      .strategy-img {
        display: block;
        width: 65%;
        height: 100%;
        margin: 0 auto;
      }

      .cards-container {
        display: flex;
        align-items: stretch;
        gap: 0.8rem;
        margin: 2rem 0 3.5rem 0;

        .card {
          flex: 1;
          background-color: #eaf3ff;
          border-radius: 20px;
          color: $blue-600;
          font-weight: 400;
          font-size: 28px;
          padding: 3.2rem 1rem;
          text-align: center;
          display: flex;
          align-items: center;
          justify-content: center;
        }

        @media screen and (max-width: 760px) {
          gap: 0.5rem;
          margin: -2rem 0 1.5rem 0;
          .card {
            flex: none;
            width: 85px;
            height: 150px;
            font-size: 12px;
            border-radius: 10px;
          }
        }
      }

      .who-we-help-container {
        .title {
          width: fit-content;
          font-size: 32px;
          padding-right: 52px;
          padding-bottom: 0.8rem;
          color: $blue-100;
        }

        .who-we-help-cards {
          display: flex;
          gap: 0.5rem;
          margin-top: 2.2rem;

          .card {
            text-align: center;
            flex: 1;
            background-color: $blue-600;
            border-radius: 10px;
            color: #fff;
            font-size: 24px;
            padding: 2.4rem 0;
            font-weight: 500;
          }

          @media screen and (max-width: 550px) {
            overflow-x: scroll;
            margin-top: 1.2rem;

            .card {
              flex: none;
              display: flex;
              justify-content: center;
              align-items: center;
              text-align: center;
              width: 75px;
              height: 100px;
              font-size: 12px;
              padding: 0 0.5rem;
            }
          }
        }
      }

      .os-diagram-container {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 500px;
        margin: 1rem 0rem;
        margin-bottom: 4rem;

        .os-diagram {
          display: flex;
          justify-content: space-between;
          height: 400px;
          width: 700px;
          background-color: $blue-400;
          padding: 2rem;
          border-radius: 22.5px;
          position: relative;

          .content-container {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            gap: 1.5rem;
            width: 30%;

            .content-box-black,
            .content-box-white {
              padding: 0.8rem 1rem;
              border-radius: 6px;
              background-color: black;
              color: white;
              font-size: 12px;
              width: 140px;
              margin-right: 1rem;
              text-align: center;
              cursor: pointer;
            }

            .content-box-white {
              background-color: white;
              color: black;
            }
          }

          .loop-1 {
            position: relative;
            width: 70%;
            border-radius: 22.5px;
            height: 100%;
            background-color: $blue-500;
            padding: 1rem;
            padding-left: 2rem;
            display: flex;
            justify-content: space-between;
            align-items: center;

            .content-container {
              display: flex;
              flex-direction: column;
              justify-content: center;
              align-items: center;
              gap: 1.5rem;
              width: 30%;

              .content-box-black,
              .content-box-white {
                padding: 0.8rem 1rem;
                border-radius: 6px;
                background-color: black;
                color: white;
                font-size: 12px;
                // width: 90px;
                margin-left: 2rem;
                text-align: center;
                cursor: pointer;
              }

              .content-box-white {
                background-color: white;
                color: black;
              }
            }

            .loop-1-tangent {
              position: absolute;
              left: 0;
              bottom: calc(-60px - 2.3rem);
              width: 1px;
              height: calc(80px + 2.3rem);
              background-color: black;

              .tangent-point {
                position: absolute;
                bottom: 0;
                left: -3.5px;
                height: 7px;
                width: 7px;
                background-color: black;
                border-radius: 50%;
              }

              .tangent-text {
                position: absolute;
                left: 10px;
                bottom: 0;
                font-weight: 500;
                color: $blue-100;
                font-size: 12.45px;
                letter-spacing: 0.5px;
              }
            }

            .loop-2 {
              display: flex;
              align-items: center;
              justify-content: end;
              border-radius: 22.5px;
              width: 50%;
              height: 40%;
              background-color: $blue-600;
              position: relative;

              p {
                color: #ffffff;
                font-weight: 700;
                font-size: 18px;
                margin-right: 1rem;
              }

              .loop-2-tangent {
                position: absolute;
                left: 0;
                bottom: calc(-60px - 2.3rem - 107px);
                width: 1px;
                height: calc(80px + 2.3rem + 107px);
                background-color: black;

                .tangent-point {
                  position: absolute;
                  bottom: 0;
                  left: -3.5px;
                  height: 7px;
                  width: 7px;
                  background-color: black;
                  border-radius: 50%;
                }

                .tangent-text {
                  position: absolute;
                  left: 10px;
                  bottom: 0;
                  font-weight: 500;
                  color: $blue-100;
                  font-size: 12.45px;
                  letter-spacing: 0.5px;
                }
              }
            }
          }
        }

        .strategy-container-tangent {
          position: absolute;
          left: 0;
          bottom: -60px;
          width: 1px;
          height: 80px;
          background-color: black;

          .tangent-point {
            position: absolute;
            bottom: 0;
            left: -3.5px;
            height: 7px;
            width: 7px;
            background-color: black;
            border-radius: 50%;
          }

          .tangent-text {
            position: absolute;
            left: 10px;
            bottom: 0;
            font-weight: 500;
            color: $blue-100;
            font-size: 12.45px;
            letter-spacing: 0.5px;
          }
        }
      }
    }

    .solution-container {
      .solution-title {
        font-size: 36px;
        span {
          color: $blue-100;
        }
      }

      .solution-separator {
        height: 1px;
        width: 274px;
        background-color: black;
        margin-top: 1rem;
      }
    }
  }

  @media screen and (min-width: 850px) and (max-width: 1200px) {
    .curved-white-container {
      .strategy-container {
        .cards-container {
          .card {
            font-size: 20px;
          }
        }
      }
    }
  }

  @media screen and (max-width: 850px) {
    // padding: 1rem;
    margin-top: 1rem;

    .curved-white-container {
      padding: 2rem;

      .strategy-container {
        .os-title {
          font: 24px;
        }

        .strategy-img-container {
          width: 100%;
          margin-top: 1.5rem;
          margin-bottom: 4rem;

          .strategy-img {
            display: block;
            width: 100%;
            height: 100%;
            margin: 0 auto;
          }
        }
      }

      .who-we-help-container {
        color: $blue-100;
        .title {
          font-size: 20px !important;
        }

        .separator {
          margin-top: -0.5rem !important;
        }
      }
    }
  }
}
