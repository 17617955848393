$gray100: #e9e9e9;
$gray150: #dddddd;
$gray200: #d1d1d1;
$gray250: #e2e2e2;
$gray300: #6b6b6b;
$gray350: #606060;
$gray400: #5a5a5a;
$gray450: #e3e3e3;
$gray500: #111111;
$gray600: #a2a2a2;
$gray700: #d7d7d7;

$blue-100: #1868db;
$blue-200: #1868db1a;
$blue-300: #1a2130;
$blue-400: #6a9ae0;
$blue-500: #357ce2;
$blue-600: #1058c0;
$blue-700: #eaf3ff;
$blue-800: #f2f7ff;
