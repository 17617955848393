@import "../../DesignSystem/library";
.wrapper {
  font-family: $font-primary;
  display: flex;
  flex-direction: column;
  gap: 3rem;
  padding: 3.5rem;
  @media (max-width: 768px) {
    gap: 2rem;
    padding: 1.5rem;
  }
  @media (max-width: 480px) {
    gap: 1rem;
    padding: 0.8rem 1rem 1.2rem 1rem;
  }

  .header {
    position: relative;
    .header-img {
      width: 100%;
      height: 556px;
      object-fit: cover;
      border-radius: 60px;
      filter: brightness(0.8) contrast(1.1);

      @media (max-width: 768px) {
        border-radius: 20px;
        height: 450px;
        // height: 60vh;
      }

      @media (max-width: 480px) {
        height: 350px;
        border-radius: 16px;
      }
    }
    .title {
      font-family: $font-primary;
      font-size: 98px;
      font-weight: 200;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      color: #fff;
      white-space: nowrap;
      @media (max-width: 970px) {
        font-size: 50px;
      }

      @media (max-width: 480px) {
        font-size: 26px;
      }
    }
  }
  .re-tech-card-wrapper {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    width: 100%;
    border-radius: 30px;
    height: 646px;
    background-color: #fff;
    padding: 6rem 4rem 4rem 6rem;
    // padding-left: 6rem;
    position: relative;

    .re-tech-card {
      width: 45%;
      color: #2a2a2a;
      font-family: $font-primary;
      font-size: clamp(1rem, 5vw, 40px);
      font-weight: 400;

      .blue-text {
        color: #1868db;
        font-weight: 450;
      }
    }

    .windmill-img {
      position: absolute;
      right: 30px;
      bottom: 0;
      width: clamp(200px, 50%, 612px);
      height: auto;
    }

    @media (max-width: 1270px) {
      padding: 3rem;

      .re-tech-card {
        font-size: clamp(1rem, 4.5vw, 40px);
      }

      .windmill-img {
        width: clamp(200px, 70%, 412px);
      }
    }

    @media (max-width: 970px) {
      padding: 2.5rem;
      height: 546px;

      .re-tech-card {
        font-size: clamp(1rem, 4.5vw, 35px);
      }

      .windmill-img {
        width: clamp(180px, 65%, 422px);
      }
    }
    @media (max-width: 900px) {
      border-radius: 22px;


      padding: 2.5rem;
      height: 546px;

      .re-tech-card {
        font-size: clamp(1rem, 4.5vw, 30px);
      }

      .windmill-img {
        width: clamp(180px, 65%, 372px);
      }
    }

    @media (max-width: 700px) {
      border-radius: 20px;

      padding: 2rem;
      height: 446px;

      .re-tech-card {
        font-size: clamp(0.9rem, 4vw, 30px);
      }

      .windmill-img {
        right: 12px;
        width: clamp(150px, 60%, 332px);
      }
    }

    @media (max-width: 480px) {
      border-radius: 16px;

      padding: 1.5rem;
      height: 346px;

      .re-tech-card {
        font-size: clamp(0.8rem, 3.5vw, 20px);
      }

      .windmill-img {
        width: clamp(120px, 55%, 252px);
      }
    }
    @media (max-width: 430px) {
      padding: 1.5rem;
      height: 246px;

      .re-tech-card {
        font-size: clamp(0.8rem, 3.5vw, 20px);
      }

      .windmill-img {
        width: clamp(120px, 55%, 182px);
      }
    }
  }
  .clem-ai-wrapper {
    display: flex;
    flex-direction: column;
    gap: 2rem;
    background-color: #fff;
    justify-content: center;
    width: 100%;
    padding: 3rem;
    border-radius: 30px;
    @media (max-width: 1200px) {
      padding: 2rem;
    }
    @media (max-width: 800px) {
      border-radius: 16px;
      padding: 1rem;
      gap: 1rem;
    }
    .clem-ai-title {
      font-size: 48px;
      font-weight: 450;
      .blue-text {
        color: #1868db;
        font-weight: 450;
      }
      @media (max-width: 1200px) {
        font-size: 40px;
      }
      @media (max-width: 800px) {
        font-size: 35px;
      }
      @media (max-width: 500px) {
        font-size: 25px;
      }
    }
    .divider {
      border: 1px solid #111111;
      width: 100%;
      margin-bottom: 1rem;
      @media (max-width: 600px) {
        border: 0.5px solid #111111;
        margin-bottom: 10px;
      }
    }
    .clem-ai-img-wrapper {
      width: 100%;
      box-sizing: border-box;

      position: relative;
      .clem-ai-img {
        width: 100%;
        height: 526px;
        border-radius: 30px;
        object-fit: cover;
        filter: brightness(0.6) contrast(1.1) blur(1.5px);

        @media (max-width: 768px) {
          border-radius: 25px;
          height: 60vh;
        }

        @media (max-width: 480px) {
          border-radius: 25px;
          height: 50vh;
        }
      }
      .clem-ai-title {
        font-family: $font-primary;
        font-size: 40px;
        font-weight: 420;
        width: 100%;
        max-width: 80%;
        text-align: center;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        color: #fff;
        .bold-text {
          font-weight: 500;
        }
        @media (max-width: 1200px) {
          font-size: 40px;
        }
        @media (max-width: 970px) {
          font-size: 30px;
        }

        @media (max-width: 480px) {
          font-size: 20px;
        }
      }
    }
  }
  .clem-stretegy-container {
    padding: 3rem;
    border: none;
    background-color: #ffffff;
    width: 100%;
    border-radius: 32px;
    @media (max-width: 1200px) {
      padding: 2rem;
      border-radius: 25px;
    }
    @media (max-width: 800px) {
      padding: 1rem;
      border-radius: 16px;
    }
    .clem-stretegy-heading {
      font-size: 48px;
      font-weight: 450;
      color: #111111;
      @media (max-width: 1200px) {
        font-size: 32px;
      }
      @media (max-width: 800px) {
        margin-top: 10px;
        font-size: 27px;
      }

      span {
        font-weight: 550;
        color: #1058c0;
      }
    }
    .divider {
      background-color: #111111;
      width: 100%;
      height: 1px;
      margin-top: 30px;
      @media (max-width: 600px) {
        margin-top: 15px;
      }
    }
    .clem-stretegy-description-container {
      display: flex;
      justify-content: flex-end;
      margin-top: 3rem;
      margin-bottom: 3rem;
      @media (max-width: 600px) {
        margin-top: 1rem;
        margin-bottom: 1rem;
        justify-content: center;
      }

      .clem-stretegy-description {
        width: 40%;
        font-size: 20px;
        font-weight: 300;
        line-height: 24px;
        padding: 5px;
        @media (max-width: 1200px) {
          font-size: 15px;
        }
        @media (max-width: 600px) {
          width: 100%;
        }
      }
    }
  }
  .get-in-touch-container {
    display: flex;
    height: 515px;
    gap: 1.5rem;
    letter-spacing: 1px;

    .git-image-container {
      border-radius: 20px;
      flex: 1.3;

      img {
        border-radius: 20px;
      }
    }

    .git-card {
      border-radius: 20px;
      background-color: white;
      flex: 2;
      padding: 0rem 3rem;
      padding-top: 3rem;
      position: relative;
      @media (max-width:600px) {
        border-radius: 16px;
        
      }

      .git-card-content {
        font-size: 52px;
        font-weight: 300;
        margin-bottom: 4rem;
        width: 500px;

        span {
          font-weight: 600;
          color: $blue-100;
        }
      }
    }

    .separator {
      width: 100%;
      height: 1px;
      background-color: black;
      margin-top: 2rem;
    }

    .git-btn-container {
      position: absolute;
      bottom: 2.5rem;
      right: 3rem;
    }

    @media screen and (max-width: 950px) {
      padding: 0;
      height: calc(249.86px - 2rem);
      margin-top: 0rem;
      margin-bottom: 3rem;
      letter-spacing: 0.5px;

      .git-card {
        height: 249.86px;
        padding: 1.5rem;
        .git-card-content {
          width: 100%;
          font-size: 22.32px;
        }

        .separator {
          width: 100%;
          height: 0.5px;
          background-color: black;
          margin-top: 1rem;
        }

        .git-btn-container {
          position: absolute;
          bottom: 1rem;
          right: 1rem;
        }
      }
    }
  }
}
