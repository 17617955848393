@import "../../../DesignSystem/library";

.clem-vision-container-parent {
  margin-top: -2rem;
  margin-bottom: -1rem;
  font-family: $font-primary;

  @media (max-width: 760px) {
    margin-top: 0rem;
    margin-bottom: 1.5rem;
  }

  @media (max-width: 480px) {
    margin-bottom: 1rem;
  }

  .curve-white-container {
    padding: 3rem;
    background-color: white;
    border-radius: 30px;
    font-weight: 450;

    @media (max-width: 760px) {
      padding: 1.5rem;
      border-radius: 20px;
    }

    @media (max-width: 480px) {
      padding: 1rem;
      border-radius: 15px;
    }

    .clem-vision-heading {
      font-size: 43px;
      line-height: 52.6px;
      color: #1868db;

      @media (max-width: 760px) {
        font-size: 36px;
        line-height: 44px;
      }

      @media (max-width: 480px) {
        font-size: 18px;
        line-height: 36px;
      }
    }

    .divider {
      width: 100%;
      background-color: #a7a7a7;
      height: 1px;
      margin-top: 15px;

      @media (max-width: 768px) {
        margin-top: 5px;
      }
    }

    .clem-text-container {
      width: 100%;
      display: flex;
      justify-content: flex-end;
      margin-top: 40px;

      @media (max-width: 760px) {
        margin-top: 10px;
      }

      .clem-text {
        width: 40%;
        font-size: 24px;
        font-weight: 300;
        line-height: 28px;
        color: #111111;

        @media (max-width: 760px) {
          width: 100%;
          font-size: 12px;
          line-height: 20px;
        }

        @media (max-width: 480px) {
          width: 100%;
          font-size: 10px;
          line-height: 18px;
        }

        span {
          color: #1868db;
          font-weight: 500;
        }
      }
    }

    .clem-card-container-parent {
      display: flex;
      flex-direction: row;
      gap: 3rem;
      align-items: center;
      justify-content: center;
      margin-top: 4.3rem;

      @media (max-width: 760px) {
        gap: 1.5rem;
        margin-top: 2.3rem;
      }

      @media (max-width: 480px) {
        gap: 1rem;
        margin-top: 1rem;
      }

      .clem-enablement-card-conatiner {
        width: 48%;
        height: 371px;
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;
        border-radius: 39px;
        color: white;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        padding: 40px;
        cursor: pointer;

        @media (max-width: 760px) {
          height: 150px;
          width: 100%;
          border-radius: 15px;
          padding: 20px;
          width: 50%;
        }

        @media (max-width: 480px) {
          height: 100px;
          width: 100%;
          border-radius: 10px;
          width: 50%;
          padding: 10px;
        }

        .navigate-icon {
          display: flex;
          justify-content: flex-end;
        }

        .heading-container {
          width: 60%;
          font-weight: 600;
          font-size: 48px;
          @media (max-width: 760px) {
            font-size: 16px;   
          }
  
          @media (max-width: 480px) {
            font-size: 12px;
            width: 50%;
          }
        }
      }
    }
  }
}