@import "../../../DesignSystem/library";

.clem-ecosystem-container-parent {
  margin-top: 2rem;
  font-family: $font-primary;
  @media (max-width: 760px) {
    margin-top: 2rem;
    margin-bottom: 2rem;
  }

  @media (max-width: 480px) {
    margin-top: 1rem;
    margin-bottom: 1rem;
  }
  .curve-white-comtainer {
    padding: 3rem;
    background-color: white;
    border-radius: 30px;
    font-weight: 450;
    @media (max-width: 760px) {
      padding: 1.5rem;
      border-radius: 20px;
    }

    @media (max-width: 480px) {
      padding: 1rem;
      border-radius: 15px;
    }
    .clem-ecosystem-heading {
      font-size: 43px;
      line-height: 52.6px;
      color: #1868db;
      @media (max-width: 760px) {
        font-size: 36px;
        line-height: 44px;
      }

      @media (max-width: 480px) {
        font-size: 18px;
        line-height: 36px;
      }
    }

    .divider {
      width: 100%;
      background-color: #a7a7a7;
      height: 1px;
      margin-top: 15px;
      @media (max-width: 768px) {
        margin-top: 5px;
      }
    }

    .clem-ecosystem-image {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-top: 4rem;
      @media (max-width: 760px) {
        margin-top: 1.5rem;
      }

      @media (max-width: 480px) {
        margin-top: 1rem;
      }
      .clem-image{
        width:100%;
      }
    }
  }
}
