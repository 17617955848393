@import "../../../../DesignSystem/library";

.solutions-platform {
  height: 360px;
  width: 590px;
  color: $gray300;
  display: flex;
  gap: 2rem;

  .by-role-section,
  .solution-section {
    padding: 0.4rem;
    .section-header {
      font-family: $font-quaternary;
      font-weight: 400;
      font-size: 16px;
      margin-bottom: 1.3rem;
    }

    .by-role-container {
      font-family: $font-tertiary;
    }
  }

  .section-partitioner {
    width: 1px;
    height: 100%;
    background-color: $gray150;
  }
}
